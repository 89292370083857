import logo from './logo.svg';
import './App.css';
import llama from './images/LLAMA_WIF_BOOTS.png';
import moon from './images/MOON_WIF_BOOTS.png';

function App() {
  return (
    <div className="container">
      <h1 style={{color: '#FFFFFF', fontFamily: 'Work Sans', zIndex: 1, marginLeft: '30px'}}>WIF BOOTS</h1>
      <div style={{display: 'flex', justifyContent: 'center', marginLeft: '20px', marginRight: '20px', zIndex: 1, padding: '10px', gap: '10px'}}>
        <button style={{ 
          padding: '10px 40px', 
          backgroundColor: '#595959', 
          color: '#FFFFFF',
          border: 'none',
          fontSize: '18px',
          fontFamily: 'Work Sans'}} >TALK BOOTS</button>
        <button style={{
          padding: '10px 40px', 
          backgroundColor: '#D81529', 
          color: '#FFFFFF',
          border: 'none',
          fontSize: '18px',
          fontFamily: 'Work Sans'}}>BUY $BOOTS</button>
        
      </div>
      <img src={moon} className="image" alt="logo" />
    </div>
  );
}

export default App;
